import { FC, useEffect, useState } from "react";
import {
  EntityListCard,
  SearchDropDownComponent,
  YearTagPill,
} from "../../../components";
import { handleTypeIcon } from "../../../types/enums/iconsEnum";
import { ENTITY_TYPE_IDS } from "../../../types/constants";
import {
  EntityFilter,
  EntityFinancialFilter,
} from "../../../types/interfaces/entityInterfaces";
import {
  getCountyEntityList,
  getCountyEntitiesByEntityTypeID,
} from "../../countyPage/countyPage.service";
import { getEntityTypeInCountyList } from "../localDistrictsLandingPage.service";

type EntityFinancialTableProps = {
  fiscalYears: string[];
};

const LocalDistrictEntityFinancialTable: FC<EntityFinancialTableProps> = ({
  fiscalYears,
}) => {
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [selectedFiscalYear, setSelectedFiscalYear] = useState<string>("2023");
  const [selectedEntity, setSelectedEntity] = useState<EntityFilter>();
  const [selectedCounty, setSelectedCounty] = useState<EntityFinancialFilter>();
  const [entitiesForSearch, setEntitiesForSearch] = useState<EntityFilter[]>();
  //eslint-disable-next-line
  const [countyEntities, setCountyEntities] = useState<any>();
  //eslint-disable-next-line
  const [counties, setCounties] = useState<any>();

  useEffect(() => {
    const handleGetCountyEntityList = async () => {
      const res = await getCountyEntityList();
      if (res?.length && !selectedEntity) {
        setSelectedEntity(res[0]);
      }
    };

    handleGetCountyEntityList();
  }, []);

  useEffect(() => {
    const handleEntityTypeInCountyList = async () => {
      //eslint-disable-next-line
      const res: any = await getEntityTypeInCountyList(
        selectedEntity?.type || ENTITY_TYPE_IDS.FIRE_DISTRICT
      );
      setCounties(res);
      setSelectedCounty(res[0]);
    };
    handleEntityTypeInCountyList();
  }, [selectedEntity]);

  useEffect(() => {
    const handleEntitiesByCounty = async () => {
      setIsLoading(true);
      //eslint-disable-next-line
      const res: any = await getCountyEntitiesByEntityTypeID(
        selectedEntity?.type || ENTITY_TYPE_IDS.ABATEMENT_DISTRICT,
        selectedCounty?.id || 0,
        selectedFiscalYear
      );

      const filteredRes = res.map((entity: EntityFinancialFilter) => {
        if (entity.reportDocs) {
          entity.reportDocs = entity.reportDocs.filter((doc) => {
            return doc.Kind !== "Annual";
          });
        }
        return entity;
      });
      setCountyEntities(filteredRes);
      setIsLoading(false);
    };
    handleEntitiesByCounty();
  }, [selectedCounty, selectedEntity, selectedFiscalYear]);

  useEffect(() => {
    const handleEntitiesForSearch = async () => {
      const res: EntityFilter[] = await getCountyEntityList(selectedCounty?.id);
      setEntitiesForSearch(res);
    };
    handleEntitiesForSearch();
  }, [selectedCounty]);

  return (
    <>
      <div className="pill-container">
        {fiscalYears?.map((year) => (
          <YearTagPill
            key={year}
            year={year}
            selectedYear={selectedFiscalYear}
            setSelectedYear={setSelectedFiscalYear}
          />
        ))}
      </div>
      <div className="dual-search-container">
        <div>
          <h6 className="ld-entity-search-subheader">Search Entity</h6>
          <div className="search-component-container">
            <SearchDropDownComponent
              entitiesData={entitiesForSearch}
              selectedItem={selectedEntity}
              setSelectedItem={setSelectedEntity}
              isEntitiesFiltered={true}
            />
          </div>
        </div>
        <div>
          <h6 className="ld-entity-search-subheader">Search by County</h6>
          <div className="search-component-container">
            <SearchDropDownComponent
              isEntitiesFiltered
              entitiesData={counties}
              selectedItem={selectedCounty}
              setSelectedItem={setSelectedCounty}
            />
          </div>
        </div>
      </div>
      <div className="filter-table">
        {countyEntities?.length ? (
          countyEntities?.map((data: EntityFinancialFilter) => (
            <EntityListCard
              key={data.id}
              isLoading={isLoading}
              entityType={selectedEntity?.type}
              Icon={handleTypeIcon(selectedEntity?.type)}
              entityName={data.name}
              actualExpenditures={data.expenseActual}
              actualRevenue={data.revenueActual}
              budgetedExpenditures={data.expenseBudget}
              budgetedRevenue={data.revenueBudget}
              reportDocs={data.reportDocs}
            />
          ))
        ) : (
          <EntityListCard
            noData
            entityName=""
            isLoading={isLoading}
            actualExpenditures={""}
            actualRevenue={""}
            budgetedExpenditures={""}
            budgetedRevenue={""}
          />
        )}
      </div>
    </>
  );
};
export default LocalDistrictEntityFinancialTable;
