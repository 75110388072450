import { useEffect, useLayoutEffect, useRef, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { City } from "styled-icons/boxicons-solid";
import IdahoStateSeal from "../../styles/images/idahoStateSeal.png";
import Footer from "../footer/Footer";
import Header from "../header/Header";
import {
  AboutSection,
  InfoBubble,
  LoadingBar,
  LoadingState,
} from "../../components";
import CityFinancialTable from "./cityComponents/CityFinancialTable";
import { PopulationIcon } from "../../styles/icons";
import {
  API_CONSTANTS,
  ABOUT_SECTION_CONSTANTS,
  NAV_TAGS,
} from "../../types/constants";
import { requestFiscalYearList } from "../../api/searchType.api";
import "./cityLandingPage.scss";
import CitySearchFeature from "./cityComponents/CitySearchFeature";

const CityLandingPage = () => {
  const [loadingState, setLoadingState] = useState<LoadingState>(
    LoadingState.InProgress
  );
  const [fiscalYears, setFiscalYears] = useState<string[]>([]);

  const navigate = useNavigate();
  const location = useLocation();
  const { state } = location;

  const exploreDataRef = useRef<HTMLDivElement>(null);
  const allCityBudgetsRef = useRef<HTMLDivElement>(null);
  const citySalaryCostsRef = useRef<HTMLDivElement>(null);

  useLayoutEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    const handleLoad = async () => {
      const fiscalYears = await requestFiscalYearList();
      setFiscalYears(fiscalYears);
    };

    handleLoad();
  }, []);

  useEffect(() => {
    if (state) {
      if (state.tag === NAV_TAGS.EXPLORE_CITY_DATA) {
        exploreDataRef.current?.scrollIntoView({ behavior: "smooth" });
      } else if (state.tag === NAV_TAGS.CITY_ALL_CITY_BUDGETS) {
        allCityBudgetsRef.current?.scrollIntoView({ behavior: "smooth" });
      } else if (state.tag === NAV_TAGS.CITY_SALARY_COSTS) {
        citySalaryCostsRef.current?.scrollIntoView({ behavior: "smooth" });
      }
      navigate(location.pathname, { replace: true });
    }
  }, [state]);

  return (
    <div className="landing-page-container landing-page-picture-background">
      {
        <LoadingBar
          loadingState={loadingState}
          setLoadingState={setLoadingState}
        />
      }
      <header>
        <Header />
      </header>
      <main>
        <div className="landing-page-hero-container">
          <span className="landing-page-hero-data">
            <div className="landing-page-hero-info">
              <img
                className="landing-page-idaho-state-seal"
                src={IdahoStateSeal}
                alt=""
              />
              <span className="landing-page-explore-info-text">
                <h2 className="landing-page-hero-header">
                  Explore Idaho's Cities
                </h2>
                <h6 className="landing-page-hero-subheader">
                  Idaho has 198 cities ranging in size and population while
                  providing a unique set of services to its citizens
                </h6>
              </span>
            </div>
            <div className="landing-page-info-bubbles">
              <InfoBubble
                titleImage={
                  "https://sco-loctrans-images.s3-us-gov-west-1.amazonaws.com/dashboard-header-images/CurvedHeaderTotalCities.svg"
                }
                Icon={City}
                infoAmount={"198"}
                infoDetailText={"Cities"}
                bottomHeader={"Incorporated Cities"}
                bottomSubHeader={"As of Census 2023"}
              />
              <InfoBubble
                titleImage={
                  "https://sco-loctrans-images.s3-us-gov-west-1.amazonaws.com/dashboard-header-images/CurvedHeaderLargestCity.svg"
                }
                infoDetailText={"People"}
                Icon={PopulationIcon}
                infoAmount={"235,421"}
                bottomHeader={"City of Boise"}
                bottomSubHeader={"As of Census 2023"}
              />
              <InfoBubble
                titleImage={
                  "https://sco-loctrans-images.s3-us-gov-west-1.amazonaws.com/dashboard-header-images/CurvedHeaderSmallestCity.svg"
                }
                Icon={PopulationIcon}
                infoAmount={"<5"}
                infoDetailText={"People"}
                bottomHeader={"City of Warm River"}
                bottomSubHeader={"As of Census 2023"}
              />
            </div>
          </span>
        </div>
        <span className="hero-separator" />
        <AboutSection
          imageUrl={API_CONSTANTS.imageBucket + "/ui-images/sco-ui-image-6.png"}
          imageAltText={ABOUT_SECTION_CONSTANTS.CITY.IMG_ALT}
          title={ABOUT_SECTION_CONSTANTS.CITY.TITLE}
          description={ABOUT_SECTION_CONSTANTS.CITY.DESCRIPTION}
          dataInfo={ABOUT_SECTION_CONSTANTS.CITY.DATA_INFO}
          ref={exploreDataRef}
        />
        <div ref={allCityBudgetsRef}>
          <CitySearchFeature />
        </div>
      </main>
      <footer>
        <Footer />
      </footer>
    </div>
  );
};

export default CityLandingPage;
