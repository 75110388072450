export const ENTITY_TYPE_IDS = {
  AQUIFER_RECHARGE_DISTRICT: 1,
  PEST_CONTROL_DISTRICT: 2,
  WATER_DISTRICT: 3,
  JUNIOR_COLLEGE_DISTRICT: 4,
  GRAZING_DISTRICT: 5,
  FIRE_DISTRICT: 6,
  URBAN_RENEWAL: 7,
  ABATEMENT_DISTRICT: 8,
  LIBRARY_DISTRICT: 9,
  AMBULANCE_DISTRICT: 10,
  WASTE_DISPOSAL: 11,
  SEWER_WATER_DISTRICT: 12,
  AUDITORIUM_DISTRICT: 13,
  SEWER_DISTRICT: 14,
  COUNTY: 15,
  RECREATION_DISTRICT: 16,
  INFRASTRUCTURE_DISTRICT: 17,
  AGRICULTURAL_COMMISSION: 18,
  FAIR_BOARD: 19,
  TV_TRANSLATOR_DISTRICT: 20,
  CITY: 21,
  CEMETERY_DISTRICT: 22,
  JOINT_POWERS: 23,
  HIGHWAY_DISTRICT: 24,
  CAPITAL_CRIMES_DEFENSE_PROGRAM: 25,
  INTERMODAL_COMMERCE_AUTHORITY: 26,
  PORT_DISTRICT: 27,
  HOSPITAL_DISTRICT: 28,
  AIRPORT: 29,
  SOIL_WATER_DISTRICT: 30,
  IRRIGATION_DISTRICT: 31,
  LEVEE_DISTRICT: 32,
  DRAINAGE_DISTRICT: 33,
  OTHER_DISTRICT: 34,
  TRANSPORTATION_AUTHORITY: 35,
  FLOOD_CONTROL_DISTRICT: 36,
  WATERSHED: 37,
  STATE_AGENCY: 38,
  HEALTH_DISTRICT: 39,
};
