import { API_CONSTANTS } from "./envApiConstants";
import { NAV_TAGS } from "./navTagConstants";
import { URL_CONSTANTS } from "./urlConstants";

export const HOME_PAGE_CARDS_DATA = {
  workforceSalary: [
    {
      TITLE: "Employee Pay Rates",
      DESCRIPTION:
        "The Employee Pay Rates report provides access to the compensation details of over 24,000 state employees. Here, citizens can explore... ",
      NAVIGATION_LINK: URL_CONSTANTS.WORKFORCE_SALARY,
      NAVIGATION_TAG: NAV_TAGS.EMPLOYEE_PAY_RATES,
      IMAGE:
        "https://sco-loctrans-images.s3.us-gov-west-1.amazonaws.com/TransparentIdahoLogos/Picture+Updates+12.26.2023/Employee_Pay_Rate_Report%5B1%5D.jpg",
    },
    {
      TITLE: "Employee Count",
      DESCRIPTION:
        "The Employee Count Report is a resource offering insights into the ongoing employment numbers of each agency and the overall state. ",
      NAVIGATION_LINK: URL_CONSTANTS.WORKFORCE_SALARY,
      NAVIGATION_TAG: NAV_TAGS.EMPLOYEE_COUNT,
      IMAGE:
        API_CONSTANTS.imageBucket + "/powerbi-images/sco-report-image-6.jpg",
    },
    {
      TITLE: "Workforce Tenure",
      DESCRIPTION:
        "The Workforce Tenure Report provides insight into the duration of service and tenure trends for state employees.",
      NAVIGATION_LINK: URL_CONSTANTS.WORKFORCE_SALARY,
      NAVIGATION_TAG: NAV_TAGS.TENURE,
      IMAGE:
        API_CONSTANTS.imageBucket + "/ui-images/sco-ui-employee-pay-rates.png",
    },
  ],
  state: [
    {
      TITLE: "Budget",
      DESCRIPTION:
        "Inspect Idaho's budget allocations within this report, unveiling the state's financial priorities, and allocating funding for areas like education, healthcare, and public safety. Delve in to dissect detailed…",
      NAVIGATION_LINK: URL_CONSTANTS.STATE,
      NAVIGATION_TAG: NAV_TAGS.STATE_BUDGET,
      IMAGE: API_CONSTANTS.imageBucket + "/ui-images/sco-ui-image-14.png",
    },
    {
      TITLE: "Transactions",
      DESCRIPTION:
        "The Transaction Report provides a detailed listing of every financial transaction made by the state.",
      NAVIGATION_LINK: URL_CONSTANTS.STATE,
      NAVIGATION_TAG: NAV_TAGS.STATE_TRANSACTIONS,
      IMAGE: API_CONSTANTS.imageBucket + "/ui-images/sco-ui-transaction.png",
    },
    {
      TITLE: "State Agencies",
      DESCRIPTION: "Interested in learning more about a specific state agency?",
      NAVIGATION_LINK: URL_CONSTANTS.STATE,
      NAVIGATION_TAG: NAV_TAGS.STATE_AGENCIES,
      IMAGE:
        API_CONSTANTS.imageBucket +
        "/ui-images/state-capital-inside-upward.png",
    },
  ],
  education: [
    {
      TITLE: "Learn About Education Data",
      DESCRIPTION:
        "Through a partnership with the Idaho State Board of Education, the Idaho State Department of Education, and the Idaho System for Educational excellence, the...",
      NAVIGATION_LINK: URL_CONSTANTS.EDUCATION,
      NAVIGATION_TAG: NAV_TAGS.EXPLORE_EDUCATION_DATA,
      IMAGE: API_CONSTANTS.imageBucket + "/ui-images/sco-ui-image-4.png",
    },
    {
      TITLE: "Year Over Year Education Financials",
      DESCRIPTION:
        "Idaho public schools are funded primarily from state general funds and are supplemented by state-dedicated funds...",
      NAVIGATION_LINK: URL_CONSTANTS.EDUCATION,
      NAVIGATION_TAG: NAV_TAGS.EDUCATION_YEAR_OVER_YEAR,
      IMAGE:
        API_CONSTANTS.imageBucket + "/powerbi-images/sco-report-image-1.jpg",
    },
    {
      TITLE: "Education Salary Costs",
      DESCRIPTION:
        "This report includes position and salary information for education provider employees in the state of Idaho.",
      NAVIGATION_LINK: URL_CONSTANTS.EDUCATION,
      NAVIGATION_TAG: NAV_TAGS.EDUCATION_SALARY_COSTS,
      IMAGE:
        API_CONSTANTS.imageBucket + "/powerbi-images/sco-report-image-2.jpg",
    },
  ],
  county: [
    {
      TITLE: "Learn About County Data",
      DESCRIPTION:
        "The Local Transparency Project Team, housed in the State Controller’s Office, worked with county leadership and the Idaho Association of Counties to establish...",
      NAVIGATION_LINK: URL_CONSTANTS.COUNTY,
      NAVIGATION_TAG: NAV_TAGS.EXPLORE_COUNTIES_DATA,
      IMAGE:
        "https://sco-loctrans-images.s3.us-gov-west-1.amazonaws.com/TransparentIdahoLogos/Picture+Updates+12.26.2023/About_County_Data%5B1%5D.jpeg",
    },
    {
      TITLE: "Top County Expenditures by Category",
      DESCRIPTION:
        "Counties provide an array of services to residents and businesses that are managed by a mixture of elected local leaders and...",
      NAVIGATION_LINK: URL_CONSTANTS.COUNTY,
      NAVIGATION_TAG: NAV_TAGS.COUNTIES_TOP_TEN_COUNTIES_BY_EXPENDITURE,
      IMAGE:
        API_CONSTANTS.imageBucket + "/powerbi-images/sco-report-image-3.jpg",
    },
    {
      TITLE: "All 44 County Fund Balances by Classification",
      DESCRIPTION:
        "After providing yearly budgeted services, counties commonly have remaining cash that is set aside for several reasons.  The...",
      NAVIGATION_LINK: URL_CONSTANTS.COUNTY,
      NAVIGATION_TAG: NAV_TAGS.COUNTIES_ALL_COUNTY_FUND_BALANCES,
      IMAGE:
        API_CONSTANTS.imageBucket + "/powerbi-images/sco-report-image-4.jpg",
    },
  ],
  city: [
    {
      TITLE: "Learn About City Data",
      DESCRIPTION:
        "Cities provide an array of services to residents and businesses that are managed by a mixture of elected local...",
      NAVIGATION_LINK: URL_CONSTANTS.CITY,
      NAVIGATION_TAG: NAV_TAGS.EXPLORE_CITY_DATA,
      IMAGE:
        "https://sco-loctrans-images.s3.us-gov-west-1.amazonaws.com/TransparentIdahoLogos/Picture+Updates+12.26.2023/About_City_Data_(2)%5B1%5D.jpeg",
    },
    {
      TITLE: "View All Cities",
      DESCRIPTION:
        "Use the search functionality to search by city and view their defined fiscal year, the actuals for the previous fiscal year...",
      NAVIGATION_LINK: URL_CONSTANTS.CITY,
      NAVIGATION_TAG: NAV_TAGS.CITY_ALL_CITY_BUDGETS,
      IMAGE: API_CONSTANTS.imageBucket + "/ui-images/sco-ui-image-7.png",
    },
    {
      TITLE: "City Salary Costs",
      DESCRIPTION:
        "This report includes position and salary information for all city employees in the state of Idaho. Explore the interactive salary data to learn more about where...",
      NAVIGATION_LINK: URL_CONSTANTS.CITY,
      COMING_SOON: false,
      NAVIGATION_TAG: NAV_TAGS.SALARY_TAB,
      IMAGE:
        API_CONSTANTS.imageBucket + "/powerbi-images/sco-report-image-6.jpg",
    },
  ],
  localDistrict: [
    {
      TITLE: "Learn About Local Districts Data",
      DESCRIPTION:
        "Local Districts are entities of Local Government that provide specialized services to…",
      NAVIGATION_LINK: URL_CONSTANTS.LOCAL_DISTRICT,
      NAVIGATION_TAG: NAV_TAGS.EXPLORE_LOCAL_DISTRICT_DATA,
      IMAGE: API_CONSTANTS.imageBucket + "/ui-images/sco-ui-image-1.png",
    },
    {
      TITLE: "View All Local Districts",
      DESCRIPTION:
        "Use the search feature to find local districts that service the county. You can view each district's...",
      NAVIGATION_LINK: URL_CONSTANTS.LOCAL_DISTRICT,
      NAVIGATION_TAG: NAV_TAGS.LOCAL_DISTRICT_ALL_LOCAL_DISTRICT_BUDGETS,
      IMAGE: API_CONSTANTS.imageBucket + "/ui-images/sco-ui-image-2.png",
    },
    {
      TITLE: "Urban Renewal Agency’s Annual Reports",
      DESCRIPTION:
        "Download detailed annual reports for each Urban Renewal Agency...",
      NAVIGATION_LINK: URL_CONSTANTS.LOCAL_DISTRICT,
      NAVIGATION_TAG: NAV_TAGS.Local_DISTRICT_URBAN_RENEWAL,
      IMAGE: API_CONSTANTS.imageBucket + "/ui-images/sco-ui-image-3.png",
    },
  ],
};
