import { RefObject, useEffect, useLayoutEffect, useRef, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";

import IdahoStateSeal from "../../styles/images/idahoStateSeal.png";
import {
  BudgetIcon,
  RevenueIcon,
  ThreeDotCircleIcon,
  CompareIcon,
  PopulationIcon,
  SquareMilesIcon,
  DollarSignIcon,
} from "../../styles/icons";

import {
  API_CONSTANTS,
  ERROR_CONSTANTS,
  NAV_TAGS,
  COUNTIES_EMBEDDED_REPORTS,
  ABOUT_SECTION_CONSTANTS,
} from "../../types/constants";
import { County } from "../../types/interfaces/entityInterfaces";

import SomethingWentWrongPage from "../somethingWentWrongPage/SomethingWentWrongPage";
import Footer from "../footer/Footer";
import Header from "../header/Header";
import {
  IdahoCountiesMap,
  InfoBubble,
  LoadingBar,
  LoadingState,
  ReportSection,
  VerticalCard,
  StickyNav,
  AboutSection,
  CountyCompareData,
} from "../../components";
import CountyItem from "./countiesComponents/countyItem/CountyItem";
import { getCountyQuickFacts } from "./countiesLandingPage.service";
import "./countiesLandingPage.scss";
import "../landingPages.scss";

type StateFacts = {
  sizeSqMiles: string;
  population: string;
  budget: string;
  budgetUnit?: string;
};

const CountiesLandingPage = () => {
  const [counties, setCounties] = useState<County[]>([]);
  const [stateFacts, setStateFacts] = useState<StateFacts>({
    sizeSqMiles: "",
    population: "",
    budget: "",
    budgetUnit: "",
  });
  const [showErrorPage, setShowErrorPage] = useState<boolean>(false);
  const [loadingState, setLoadingState] = useState<LoadingState>(
    LoadingState.Starting
  );
  const [hoveredCounty, setHoveredCounty] = useState<string>("");
  const [hoveredCountyData, setHoveredCountyData] = useState<County>();
  const [cardSelected, setCardSelected] = useState(0);

  const navigate = useNavigate();
  const location = useLocation();
  const { state } = location;

  const exploreDataRef = useRef<HTMLDivElement>(null);
  const topTenCountiesByExpenditureReportRef = useRef<HTMLDivElement>(null);
  const allCountyFundBalancesRef = useRef<HTMLDivElement>(null);
  const expendituresRef = useRef<HTMLDivElement>(null);
  const revenuesRef = useRef<HTMLDivElement>(null);
  const moreDataRef = useRef<HTMLDivElement>(null);
  const compareRef = useRef<HTMLDivElement>(null);
  const allCountyRef = useRef<HTMLDivElement>(null);

  const PROPERTY_TAX_REPORT = 1;
  const ALL_COUNTY_FUND_BALANCE_REPORT = 2;
  const COUNTY_SALARIES_REPORT = 3;

  useLayoutEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    if (state) {
      if (state.tag === NAV_TAGS.EXPLORE_COUNTIES_DATA) {
        handleScroll(exploreDataRef);
      } else if (
        state.tag === NAV_TAGS.COUNTIES_TOP_TEN_COUNTIES_BY_EXPENDITURE
      ) {
        handleScroll(topTenCountiesByExpenditureReportRef);
      } else if (state.tag === NAV_TAGS.COUNTIES_ALL_COUNTY_FUND_BALANCES) {
        setCardSelected(ALL_COUNTY_FUND_BALANCE_REPORT);
        handleScroll(allCountyRef);
      } else if (state.tag === NAV_TAGS.PROPERTY_TAX_BY_PERCENT_OF_BUDGET) {
        setCardSelected(PROPERTY_TAX_REPORT);
        handleScroll(allCountyFundBalancesRef);
      }
      navigate(location.pathname, { replace: true });
    }
  }, [state]);

  const handleScroll = (refToScroll: RefObject<HTMLDivElement>) => {
    const stickyNavHeight =
      document.querySelector(".sticky-nav")?.clientHeight ?? 0;
    if (refToScroll?.current) {
      const refRect = refToScroll.current.getBoundingClientRect();
      const absoluteRefTop = refRect.top + window.scrollY;
      const scrollPosition = absoluteRefTop - stickyNavHeight;
      window.scrollTo({
        top: scrollPosition,
        behavior: "smooth",
      });
    }
  };

  useEffect(() => {
    const handleCountyData = async () => {
      setLoadingState(LoadingState.InProgress);
      const data = await getCountyQuickFacts("2023");
      if (data === ERROR_CONSTANTS.SERVER_ERROR) {
        setShowErrorPage(true);
      } else {
        setCounties(data as County[]);
        handleStateFacts(data as County[]);
      }
      setLoadingState(LoadingState.Finished);
    };
    handleCountyData();
  }, []);

  const handleStateFacts = (data: County[]) => {
    let sizeSqMiles = 0;
    let population = 0;
    let budget = 0;
    let budgetUnit = "";
    data.forEach((county) => {
      sizeSqMiles += county.sizeSqMiles;
      population += county.population;
      budget += county.budget;
    });
    if (budget > 1000000000) {
      budget = budget / 1000000000;
      budget = Math.round(budget * 1000) / 1000;
      budgetUnit = "Billion";
    } else {
      budgetUnit = "Dollars";
    }
    setStateFacts({
      sizeSqMiles: sizeSqMiles.toLocaleString(),
      population: population.toLocaleString(),
      budget: budget.toLocaleString(),
      budgetUnit: budgetUnit,
    });
  };

  useEffect(() => {
    if (hoveredCounty) {
      const county = counties.find((county) => county.name === hoveredCounty);
      setHoveredCountyData(county);
    }
  }, [hoveredCounty, counties]);

  const handleSelectedCard = (cardNumber: number) => {
    if (cardSelected === cardNumber) {
      setCardSelected(0);
    } else {
      setCardSelected(cardNumber);
      handleScroll(allCountyFundBalancesRef);
    }
  };

  return (
    <>
      {
        <LoadingBar
          loadingState={loadingState}
          setLoadingState={setLoadingState}
        />
      }
      {showErrorPage ? (
        <SomethingWentWrongPage />
      ) : (
        <div className="landing-page-page-container picture-idaho-background">
          <header>
            <Header />
          </header>
          <main>
            <div className="landing-page-hero-container">
              <span className="landing-page-hero-data">
                <div className="landing-page-hero-info">
                  <img
                    className="landing-page-idaho-state-seal"
                    src={IdahoStateSeal}
                    alt=""
                  />
                  <span className="landing-page-explore-info-text">
                    <h2 className="landing-page-hero-header">
                      Explore Idaho's Counties
                    </h2>
                    <h6 className="landing-page-hero-subheader">
                      Explore Idaho’s 44 counties, each has unique geography,
                      history, and economic drivers
                    </h6>
                  </span>
                </div>
                <div className="landing-page-info-bubbles">
                  <InfoBubble
                    titleImage={
                      !hoveredCounty
                        ? "https://sco-loctrans-images.s3-us-gov-west-1.amazonaws.com/dashboard-header-images/CurvedHeaderStateSize.svg"
                        : "https://sco-loctrans-images.s3-us-gov-west-1.amazonaws.com/dashboard-header-images/CurvedHeaderCountySize.svg"
                    }
                    infoDetailText={"Sq. Miles"}
                    Icon={SquareMilesIcon}
                    infoAmount={
                      hoveredCounty && hoveredCountyData?.sizeSqMiles
                        ? hoveredCountyData?.sizeSqMiles
                            ?.toString()
                            .replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                        : hoveredCounty &&
                          hoveredCountyData?.sizeSqMiles === null
                        ? ""
                        : stateFacts.sizeSqMiles
                    }
                    bottomHeader={`
                    
                    
                    `}
                  />
                  <InfoBubble
                    titleImage={
                      "https://sco-loctrans-images.s3-us-gov-west-1.amazonaws.com/dashboard-header-images/CurvedHeaderPopulation.svg"
                    }
                    infoDetailText={"People"}
                    Icon={PopulationIcon}
                    infoAmount={
                      hoveredCounty && hoveredCountyData?.population
                        ? hoveredCountyData.population
                            ?.toString()
                            .replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                        : hoveredCounty &&
                          hoveredCountyData?.population === null
                        ? ""
                        : stateFacts.population
                    }
                  />
                  <InfoBubble
                    titleImage={
                      "https://sco-loctrans-images.s3-us-gov-west-1.amazonaws.com/dashboard-header-images/CurvedHeaderTotalBudgetPadding.svg"
                    }
                    Icon={DollarSignIcon}
                    infoAmount={
                      hoveredCounty && hoveredCountyData?.budget
                        ? hoveredCountyData?.budget
                            ?.toString()
                            .replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                        : hoveredCounty && hoveredCountyData?.budget === null
                        ? ""
                        : stateFacts.budget
                    }
                    infoDetailText={
                      hoveredCounty && hoveredCountyData?.budget
                        ? "Dollars"
                        : stateFacts.budgetUnit
                    }
                  />
                </div>
              </span>
              <span className="explore-idaho-button">
                <IdahoCountiesMap
                  setHoveredCounty={setHoveredCounty}
                  hoveredCounty={hoveredCounty}
                />
              </span>
            </div>
            <StickyNav
              sections={[
                {
                  icon: <BudgetIcon />,
                  text: "Expenditures",
                  ref: expendituresRef,
                },
                {
                  icon: <RevenueIcon />,
                  text: "Revenues",
                  ref: revenuesRef,
                },
                {
                  icon: <CompareIcon />,
                  text: "Compare",
                  ref: compareRef,
                },
                {
                  icon: <ThreeDotCircleIcon />,
                  text: "More Data",
                  ref: moreDataRef,
                },
              ]}
            />
            <div className="counties-nav-list-section">
              <h4 className="counties-nav-list-data-header">
                EXPLORE SPECIFIC COUNTIES
              </h4>
              <p className="counties-nav-list-data-sub-header body-large">
                {
                  "Select a county below to view specific county information \nor scroll down to discover overall county data"
                }
              </p>
              <div className="counties-nav-list">
                {counties.map((county) => (
                  <CountyItem
                    key={county.id}
                    id={county.id}
                    name={county.name}
                  />
                ))}
              </div>
            </div>
            <AboutSection
              imageUrl={
                API_CONSTANTS.imageBucket + "/ui-images/sco-ui-image-5.png"
              }
              imageAltText={ABOUT_SECTION_CONSTANTS.COUNTIES.IMG_ALT}
              title={ABOUT_SECTION_CONSTANTS.COUNTIES.TITLE}
              description={ABOUT_SECTION_CONSTANTS.COUNTIES.DESCRIPTION}
              dataInfo={ABOUT_SECTION_CONSTANTS.COUNTIES.DATA_INFO}
              ref={exploreDataRef}
            />
            <div className="landing-page-reports-container">
              <div className="app-padding">
                <h4 className="landing-page-high-level-header">
                  EXPLORE HIGH LEVEL COUNTY DATA
                </h4>
                <p className="landing-page-high-level-subheader body-large">
                  Below you will find interactive reports for budgets, fund
                  balances, salaries {"\n"}and more. See information next to
                  reports for helpful descriptions and insights.
                </p>
              </div>
              <ReportSection
                scrollable
                title="Total Budget for All Counties"
                description={
                  "A county’s annual budget is prepared by the County Clerk and approved by the Board of County Commissioners. The approved budget is the projected financial plan for the upcoming operating year and authorizes the county to collect revenues and make expenditures while promoting accountability, as well as providing administrative and managerial controls. County budgets are required to be balanced, which means that the total expenses of the county cannot exceed the total incoming revenue. \n\nTo review each county’s budget individually, or compare one county budget with another, visit the interactive budget data. The information provided includes each county’s budget broken down by uniform categories over multiple fiscal years."
                }
                reportId={
                  COUNTIES_EMBEDDED_REPORTS.TotalBudgetForAllCounties.reportId
                }
              />
              <div ref={topTenCountiesByExpenditureReportRef}>
                <ReportSection
                  ref={expendituresRef}
                  scrollable
                  title="Top County Expenditures by Category "
                  description={
                    "Counties provide an array of services to residents and businesses that are managed by elected local leaders and county employees. These services range from public safety to parks and recreation, roads, sewers, water, refuse disposal, and other utilities. Counties have an additional role as a provider of many state-mandated services, such as jails, criminal justice, and elections. \n\nTo learn more about what counties spend their money on, dive into the interactive data to see the most common expenditures amongst the Idaho counties. The information provided includes the largest expenditures for counties over multiple fiscal years."
                  }
                  reportId={
                    COUNTIES_EMBEDDED_REPORTS
                      .AllCountyExpendituresByCategoryReport.reportId
                  }
                />
              </div>

              <ReportSection
                scrollable
                title="Top Counties by Expenditure"
                description={
                  "Counties provide a wide variety of services to residents and businesses. These services are set by elected local leaders and provided by county employees. \n\nTo learn more about which of the Idaho counties expended the greatest amount of funds to provide services to their citizens, dive into the interactive data to see the most common expenditures. The information provided includes the counties that expended the most funds over multiple fiscal years. \n\nImportant note: All counties have unique factors that impact their ability to collect revenue or how they must expend funds. Some counties have a greater population, square mileage, or even various amounts of federal land. These factors may impact the revenues and expenditures within the county and should be taken into consideration when viewing the data to ensure a full picture."
                }
                reportId={
                  COUNTIES_EMBEDDED_REPORTS.TopTenCountiesByExpenditureReport
                    .reportId
                }
              />
              <ReportSection
                scrollable
                title="Expenditures Per Capita"
                description={
                  "Counties spend money to provide their residents and businesses with a wide array of services. The size and population of a county will impact this data comparison greatly. Please keep in mind the vast range of size and population of the counties in Idaho when reviewing the data. County expenses will be broken down to show the cost per person in the county for the services provided. \n\nTo learn more about each county’s expenses per capita, explore the interactive data to see the average expense for services per citizen. This information is calculated by dividing the total county expenses by the county population over multiple fiscal years. "
                }
                reportId={
                  COUNTIES_EMBEDDED_REPORTS.ExpenditurePerCapitaReport.reportId
                }
              />
            </div>
            <div ref={revenuesRef}>
              <ReportSection
                scrollable
                title="Top County Revenues by Category"
                description={
                  "Counties rely on a variety of revenue sources to fund services and facilities provided to their citizens. These revenue sources include but are not limited to, user fees, licensing/permitting, taxes, and federal funding. \n\nTo learn more about where each of the Idaho counties collects their money from, dive into the interactive data to see the most common revenue sources. The information provided includes the largest revenue sources for counties over multiple fiscal years. "
                }
                blue
                reportId={
                  COUNTIES_EMBEDDED_REPORTS.AllCountyRevenuesByCategoryReport
                    .reportId
                }
              />
            </div>
            <ReportSection
              scrollable
              title="Top Counties By Revenue"
              description={
                "Counties rely on a variety of revenue sources to fund services and facilities provided to their citizens. These revenue sources include but are not limited to, user fees, licensing/permitting, taxes, and federal funding. \n\nTo learn more about counties with the largest revenue sources, examine the interactive data over multiple fiscal years. \n\nImportant note: All counties have unique factors that impact their ability to collect revenue or how they must expend funds. Some counties have a greater population, square mileage, or even various amounts of federal land. These factors may impact the revenues and expenditures within the county and should be taken into consideration when viewing the data to ensure a full picture."
              }
              blue
              reportId={
                COUNTIES_EMBEDDED_REPORTS.TopTenCountiesByRevenueReport.reportId
              }
            />

            <div
              className="more-high-level-cards-container blue"
              ref={moreDataRef}
            >
              <span>
                <h4 className="more-high-level-cards-header">
                  MORE HIGH LEVEL COUNTY DATA
                </h4>
                <h6 className="more-high-level-cards-sub-header">
                  PROPERTY TAX / FUND BALANCES / SALARIES
                </h6>
                <div className="more-high-level-cards">
                  <VerticalCard
                    cardTitle={"Property Tax by Percent \nof Budget"}
                    cardDescription="Learn about the percent of the total budget that property taxes make up for counties."
                    cardTag={NAV_TAGS.COUNTY}
                    image={
                      API_CONSTANTS.imageBucket +
                      "/powerbi-images/sco-report-image-7.jpg"
                    }
                    readMoreText={
                      cardSelected === PROPERTY_TAX_REPORT
                        ? "Read Less"
                        : "Read More"
                    }
                    readMoreAction={() =>
                      handleSelectedCard(PROPERTY_TAX_REPORT)
                    }
                  />
                  <VerticalCard
                    cardTitle={
                      "All 44 County Fund Balances \nby Classification"
                    }
                    cardDescription="Dive into the interactive data to learn about the top fund balances for each county in Idaho."
                    cardTag={NAV_TAGS.COUNTY}
                    image={
                      API_CONSTANTS.imageBucket +
                      "/powerbi-images/sco-report-image-4.jpg"
                    }
                    readMoreText={
                      cardSelected === ALL_COUNTY_FUND_BALANCE_REPORT
                        ? "Read Less"
                        : "Read More"
                    }
                    readMoreAction={() =>
                      handleSelectedCard(ALL_COUNTY_FUND_BALANCE_REPORT)
                    }
                  />
                  <VerticalCard
                    cardTitle="County Salaries Report"
                    cardDescription="This report includes position and salary information for county employees in the state of Idaho. Explore the interactive…"
                    cardTag={NAV_TAGS.COUNTY}
                    image={
                      API_CONSTANTS.imageBucket +
                      "/powerbi-images/sco-report-image-8.jpg"
                    }
                    readMoreText={
                      cardSelected === COUNTY_SALARIES_REPORT
                        ? "Read Less"
                        : "Read More"
                    }
                    readMoreAction={() =>
                      handleSelectedCard(COUNTY_SALARIES_REPORT)
                    }
                  />
                </div>
              </span>
            </div>
            <div className="more-high-level-reports-section blue">
              <div ref={allCountyFundBalancesRef}></div>
              <div ref={allCountyRef}></div>
              {cardSelected === PROPERTY_TAX_REPORT && (
                <ReportSection
                  scrollable
                  title={"Property Tax by Percent \nof Budget"}
                  description={
                    "Property taxes are one of many revenue sources collected by counties to budget for services provided to citizens. Many citizens are interested in how their property taxes contribute to their county’s budget. \n\nTo learn more about the percentage of the total county budget that makes up property taxes, explore the interactive data. The information provided represents the percentage of property taxes against the total budget over multiple fiscal years."
                  }
                  blue
                  reportId={
                    COUNTIES_EMBEDDED_REPORTS.PropertyTaxPercentOfBudgetReport
                      .reportId
                  }
                />
              )}
              {cardSelected === ALL_COUNTY_FUND_BALANCE_REPORT && (
                <ReportSection
                  scrollable
                  title={"All 44 County Fund Balances \nby Classification"}
                  description={
                    "After providing the budgeted services for each year, counties commonly have year-end funds /remaining cash set aside for several reasons. The most common purpose is to cover expenses for the first quarter of the subsequent year. This best practice provides sufficient cash flow until the first half of taxes are collected. Other best practices are to build up funds over time for upcoming projects or deferred maintenance projects.  This practice is a good way to build up reserves and limit property tax increases in the future by paying for projects by saving. Fund balances are classified as Assigned, Committed, Non-spendable, Restricted, and Unassigned; these classifications direct how the money will be used. \n\nTo learn more about fund balances, dive into the interactive data to learn about the top 4 fund balances for Idaho counties. The information provided also includes a narrative to fully understand the intent of how these fund balances will be utilized."
                  }
                  blue
                  reportId={
                    COUNTIES_EMBEDDED_REPORTS.AllCountyFundBalancesReport
                      .reportId
                  }
                />
              )}
              {cardSelected === COUNTY_SALARIES_REPORT && (
                <ReportSection
                  scrollable
                  title="County Salaries Report"
                  description={
                    "This report includes position and salary information for county employees in the state of Idaho. Explore the interactive salary data to learn more about what each county expends to pay its employees. The information provided includes the salary amounts based on the most recent report, which is updated every quarter. The data was last updated as of October 1st, 2023."
                  }
                  blue
                  reportId={
                    COUNTIES_EMBEDDED_REPORTS.CountySalariesReport.reportId
                  }
                />
              )}
            </div>
            <span className="hero-separator" />
            <span ref={compareRef}>
              <CountyCompareData />
            </span>
          </main>
          <footer>
            <Footer />
          </footer>
        </div>
      )}
    </>
  );
};

export default CountiesLandingPage;
