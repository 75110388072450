import { FC, memo } from "react";

const HealthDistrictIcon: FC<React.SVGProps<SVGSVGElement>> = memo((props) => {
  return (
    <svg
      {...props}
      width="23"
      height="25"
      viewBox="0 0 23 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M10.0929 0.53333C10.6156 0.184242 11.215 -0.000305176 11.8259 -0.000305176C12.4369 -0.000305176 13.0363 0.184242 13.559 0.53333L14.7897 1.364C16.5164 2.52866 18.483 2.788 20.4586 3.04933C21.0972 3.134 21.7364 3.218 22.369 3.33333C22.369 3.33333 22.3829 4.556 22.3684 5.11066L22.2421 9.878C22.1333 13.9667 20.4858 17.8433 17.6595 20.6607C16.3261 21.9893 14.9468 23.19 13.362 24.1873C12.8949 24.4786 12.3713 24.642 11.8352 24.6636C11.299 24.6853 10.766 24.5647 10.2808 24.312C8.45077 23.352 6.8304 22.1347 5.35079 20.6607C2.5245 17.8433 0.876939 13.9667 0.768793 9.878L0.643127 5.126C0.628022 4.56333 0.643127 3.33333 0.643127 3.33333C1.15667 3.26666 1.67988 3.21133 2.20731 3.156C4.53517 2.912 6.94035 2.66 8.91356 1.32933L10.0929 0.53333ZM10.2917 10.6667V6H12.7083V10.6667H16.9375V13.3333H12.7083V18H10.2917V13.3333H6.0625V10.6667H10.2917Z"
        fill="black"
      />
    </svg>
  );
});
export default HealthDistrictIcon;
