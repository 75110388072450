import { FC, memo } from "react";

const WarningIcon: FC<React.SVGProps<SVGSVGElement>> = memo((props) => {
  return (
    <svg
      {...props}
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M12.8646 2.99996L22.3906 19.5C22.4784 19.652 22.5246 19.8244 22.5246 20C22.5246 20.1755 22.4784 20.3479 22.3906 20.4999C22.3029 20.652 22.1766 20.7782 22.0246 20.866C21.8726 20.9537 21.7002 21 21.5246 21H2.47263C2.2971 21 2.12466 20.9537 1.97264 20.866C1.82062 20.7782 1.69439 20.652 1.60662 20.4999C1.51886 20.3479 1.47266 20.1755 1.47266 20C1.47266 19.8244 1.51886 19.652 1.60663 19.5L11.1326 2.99996C11.2204 2.84795 11.3466 2.72172 11.4987 2.63396C11.6507 2.5462 11.8231 2.5 11.9986 2.5C12.1742 2.5 12.3466 2.5462 12.4986 2.63396C12.6506 2.72172 12.7769 2.84795 12.8646 2.99996ZM4.20463 19H19.7926L11.9986 5.49996L4.20463 19ZM10.9986 16H12.9986V18H10.9986V16ZM10.9986 8.99996H12.9986V14H10.9986V8.99996Z"
        fill="#F2F7FF"
      />
    </svg>
  );
});

export default WarningIcon;
