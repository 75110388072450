import axios from "axios";
import { axiosInstance } from "../config";
import { ERROR_CONSTANTS } from "../types/constants";

async function performPostRequest<T>(url: string, data?: T) {
  try {
    const response = await axiosInstance.post(url, data);
    if (response.status === 204) {
      throw ERROR_CONSTANTS.NOT_FOUND_ERROR;
    }
    return response.data;
  } catch (error) {
    handleErrors(error);
  }
}

const handleErrors = (error: unknown) => {
  if (axios.isAxiosError(error)) {
    if (error.response) {
      if (error.response.status === 404) {
        throw ERROR_CONSTANTS.NOT_FOUND_ERROR;
      } else {
        throw ERROR_CONSTANTS.SERVER_ERROR;
      }
    }
  } else if (ERROR_CONSTANTS.NOT_FOUND_ERROR) {
    throw ERROR_CONSTANTS.NOT_FOUND_ERROR;
  } else {
    throw ERROR_CONSTANTS.SERVER_ERROR;
  }
};

export const requestFiscalYearList = () => {
  return performPostRequest(`/search?SearchType=FiscalYearList`);
};

export const requestEntitiesByTypeId = (
  entityTypeId: string | number,
  sortOnColumnName?: string,
  sortOrder?: string
) => {
  const queryParams = new URLSearchParams();

  queryParams.append("EntityTypeID", String(entityTypeId));

  if (sortOnColumnName) {
    queryParams.append("SortOnColumnName", sortOnColumnName);
  }

  if (sortOrder) {
    queryParams.append("SortOrder", sortOrder);
  }

  return performPostRequest(
    `/search?SearchType=EntitiesByTypeID&${queryParams}`
  );
};

export const requestEntityTypeInCountyList = (
  entityTypeId: string | number
) => {
  const queryParams = new URLSearchParams();
  queryParams.append("EntityTypeID", String(entityTypeId));

  return performPostRequest(
    `/search?SearchType=EntityTypeInCountyList&${queryParams}`
  );
};

export const requestEntityInfoByID = (
  entityId: string | number,
  fiscalYear?: string | number
) => {
  const queryParams = new URLSearchParams();
  queryParams.append("EntityID", String(entityId));
  if (fiscalYear) {
    queryParams.append("FiscalYear", String(fiscalYear));
  }

  return performPostRequest(`/search?SearchType=EntityInfoByID&${queryParams}`);
};

export const requestEntityInfoByEID = (
  entityEID: string | number,
  fiscalYear?: string | number
) => {
  const queryParams = new URLSearchParams();
  queryParams.append("EntityEID", String(entityEID));
  if (fiscalYear) {
    queryParams.append("FiscalYear", String(fiscalYear));
  }

  return performPostRequest(
    `/search?SearchType=EntityInfoByEID&${queryParams}`
  );
};

export const requestEntityInfoByName = (
  entityName: string,
  fiscalYear?: string | number
) => {
  const queryParams = new URLSearchParams();
  queryParams.append("EntityName", entityName);
  if (fiscalYear) {
    queryParams.append("FiscalYear", String(fiscalYear));
  }

  return performPostRequest(
    `/search?SearchType=EntityInfoByName&${queryParams}`
  );
};

export const requestSuggestEntity = (searchValue: string) => {
  const queryParams = new URLSearchParams();
  queryParams.append("SearchVal", searchValue);

  return performPostRequest(`/search?SearchType=SuggestEntity&${queryParams}`);
};

export const requestCountyFacts = (
  countyId?: string | number,
  fiscalYear?: string | number
) => {
  const queryParams = new URLSearchParams();
  if (countyId) {
    queryParams.append("CountyID", String(countyId));
  }
  if (fiscalYear) {
    queryParams.append("FiscalYear", String(fiscalYear));
  }

  return performPostRequest(`/search?SearchType=CountyFacts&${queryParams}`);
};

export const requestCountyHasEntityList = (countyId: string | number) => {
  const queryParams = new URLSearchParams();
  queryParams.append("CountyID", String(countyId));

  return performPostRequest(
    `/search?SearchType=CountyHasEntityList&${queryParams}`
  );
};

export const requestCountyEntitiesByEntityTypeID = (
  entityTypeId: string | number,
  fiscalYear?: string | number,
  countyId?: string | number,
  allowNoRecords?: boolean
) => {
  const queryParams = new URLSearchParams();
  queryParams.append("EntityTypeID", String(entityTypeId));
  if (countyId) {
    queryParams.append("CountyID", String(countyId));
  }
  if (fiscalYear) {
    queryParams.append("FiscalYear", String(fiscalYear));
  }
  if (allowNoRecords) {
    queryParams.append("AllowNoRecords", String(allowNoRecords));
  }

  return performPostRequest(`/search?SearchType=CountyEntities&${queryParams}`);
};

export const requestCountyEntityTypes = (countyId: string | number) => {
  const queryParams = new URLSearchParams();
  queryParams.append("CountyID", String(countyId));

  return performPostRequest(
    `/search?SearchType=CountyEntityTypes&${queryParams}`
  );
};

export const requestEntityFinancials = (
  entityId: string | number,
  fiscalYear: string | number
) => {
  const queryParams = new URLSearchParams();
  queryParams.append("EntityID", String(entityId));
  queryParams.append("FiscalYear", String(fiscalYear));

  return performPostRequest(
    `/search?SearchType=EntityFinancials&${queryParams}`
  );
};

export const requestFinancialsByEntityType = (
  entityTypeId: string | number,
  fiscalYear: string | number
) => {
  const queryParams = new URLSearchParams();
  queryParams.append("EntityTypeID", String(entityTypeId));
  queryParams.append("FiscalYear", String(fiscalYear));

  return performPostRequest(
    `/search?SearchType=FinancialsByEntityType&${queryParams}`
  );
};

export const requestCountyNarratives = (countyId: string | number) => {
  const queryParams = new URLSearchParams();
  queryParams.append("CountyID", String(countyId));

  return performPostRequest(
    `/search?SearchType=CountyNarratives&${queryParams}`
  );
};

export const requestWebNarratives = (ids: string) => {
  const queryParams = new URLSearchParams();
  queryParams.append("IDs", ids);

  return performPostRequest(`/search?SearchType=WebNarratives&${queryParams}`);
};

export const requestEntityReportInfo = (
  entityId: string | number,
  fiscalYear: string | number
) => {
  const queryParams = new URLSearchParams();
  queryParams.append("EntityID", String(entityId));
  queryParams.append("FiscalYear", String(fiscalYear));

  return performPostRequest(
    `/search?SearchType=EntityReportInfo&${queryParams}`
  );
};

export const requestAgenciesByClassification = (
  targetClassification: string
) => {
  const queryParams = new URLSearchParams();
  queryParams.append("targetClassification", targetClassification);

  return performPostRequest(
    `/search?SearchType=AgenciesByClassification&${queryParams}`
  );
};

export const requestAgencyById = (targetID: string) => {
  const queryParams = new URLSearchParams();
  queryParams.append("targetID", targetID);

  return performPostRequest(`/search?SearchType=Agencies&${queryParams}`);
};

export const requestAgencyNarratives = (targetID: string) => {
  const queryParams = new URLSearchParams();
  queryParams.append("targetID", targetID);

  return performPostRequest(
    `/search?SearchType=AgencyNarratives&${queryParams}`
  );
};

export const requestPageReports = (section: string) => {
  const queryParams = new URLSearchParams();
  queryParams.append("Section", section);

  return performPostRequest(`/search?SearchType=PageReports&${queryParams}`);
};

export const requestCityByEntityID = (entityID: string) => {
  const queryParams = new URLSearchParams();
  queryParams.append("EntityID", entityID);

  return performPostRequest(
    `/search?SearchType=GetCityInfoByID&${queryParams}`
  );
};
