export const ABOUT_SECTION_CONSTANTS = {
  EDUCATION: {
    TITLE: "About Education Data",
    DESCRIPTION:
      "Through a partnership with the Office of the Idaho State Board of Education, the Idaho State Department of Education, and the Idaho System for Educational Excellence the Local Transparency Team obtained three (3) years of aggregated financial data for all Idaho Education Providers. Currently, the Team is piloting efforts to provide quarterly transactional financial data on the site.",
    DATA_INFO:
      "Data provided by the Office of the State Board of Education and the Idaho State Department of Education over multiple fiscal years.",
    IMG_ALT: "Kids studying in class",
  },
  LOCAL_DISTRICTS: {
    TITLE: "About Local Districts",
    DESCRIPTION: `Local Districts are entities of Local Government that provide specialized services to a defined geographic area ranging from ambulance and fire to mosquito abatement. Local Districts can be either taxing or non-taxing. A taxing district can levy taxes to pay for those services, whereas a non-taxing district cannot.

      Following the efforts with the counties and cities, the Local Transparency Project Team, housed in the State Controller’s Office, will begin working with local district leadership to establish a template to report uniform budgeted revenue and expenditure information.

      In the meantime citizens will be able to review approved budget amount and prior year actual revenues and expenditures.

      Once completed, Transparent Idaho will provide local district revenue and expenditure data budgeted yearly and approved by local district leadership.

      Additionally, Idaho law allows municipalities in Idaho to have urban renewal agencies and provides a system to finance the plans of the agencies. An Urban Renewal Agency is charged with eliminating unsafe conditions, fostering economic development, preventing the development and extension of deterioration, and reversing the deteriorating action of the area.

      Per Idaho State Code 50-2006(5)(c) urban renewal agencies are required to prepare and file with their local governing body an annual report The agency must prepare, approve, and adopt an annual budget for filing with the local governing body for informational purposes. Their annual reports are included below.`,
    DATA_INFO:
      "Data collected from Local Districts over multiple fiscal years.",
    IMG_ALT: "A pin being placed on a map",
  },
  STATE: {
    TITLE: "About State Data",
    DESCRIPTION:
      "Transparent Idaho provides an in-depth look at the inner workings of state government by allowing citizens to delve into financial and workforce data from each state agency. Citizens can see the statewide financial perspective or dive into agency-specific data with ease.",
    DATA_INFO:
      "Data provided by the Office of the State Board of Education and the Idaho State Department of Education over multiple fiscal years..",
    IMG_ALT: "Kids studying in class",
  },
  WORKFORCE_SALARY: {
    TITLE: "About State Workforce",
    DESCRIPTION:
      "The State of Idaho is the largest employer in the state, providing employment to over 35,000 individuals. Within the state workforce reports below you will find important workforce and salary data for all state employees including pay rates, employee counts, tenure, and trends.",
    DATA_INFO: "",
    IMG_ALT: "Idaho's workforce at work",
  },
  CITY: {
    TITLE: "About City Data",
    DESCRIPTION: `The Local Transparency Project Team, housed in the State Controller’s Office, worked with city leadership and the Association of Idaho Cities to establish a template to report uniform budgeted revenue and expenditure information.

Transparent Idaho provides city revenue and expenditure data budgeted yearly and approved by city leadership. This data includes a checkbook-level analysis of incoming and outgoing funds. Due to the uniformity created throughout this effort, Idahoans now have the opportunity to analyze and compare various cities’ budgets as well as revenue and expenditure data.

`,
    DATA_INFO: "Data is collected from Cities over multiple fiscal years.",
    IMG_ALT: "A view of downtown boise at night",
  },
  COUNTIES: {
    TITLE: "About County Data",
    DESCRIPTION: `The Local Transparency Project Team, housed in the State Controller’s Office, worked with county leadership and the Idaho Association of Counties to establish a template to report uniform budgeted revenue and expenditure information.
    
 Transparent Idaho provides county revenue and expenditure data budgeted yearly and approved by county leadership. This data includes a checkbook-level analysis of incoming and outgoing funds. Due to the uniformity created throughout this effort, Idahoans now have the opportunity to analyze and compare various counties’ budgets as well as revenue and expenditure data.`,
    DATA_INFO: "Data collected from Counties over multiple fiscal years.",
    IMG_ALT: "State capital building",
  },

  VENDOR_PAYMENTS: {
    TITLE: "About Vendor Payments",
    DESCRIPTION: `The State of Idaho partners with various vendors to provide goods and services to state agencies as they serve the citizens of Idaho. The vendor payment data spans from the most utilized vendors to detailed transaction-level data. The comprehensive list captures every dollar spent, providing you with a detailed and insightful view of Idaho's financial transactions.`,
    DATA_INFO: "",
    IMG_ALT: "The state capital building",
  },
};
